import instance from '@/modules/api/csp/instance'

// Modules
import authApiModule from '@/modules/api/csp/modules/auth.api.module'
import userApiModule from '@/modules/api/csp/modules/user.api.module'
import sipApiModule from '@/modules/api/csp/modules/sip.api.module'
import globalRingtoneApiModule from '@/modules/api/csp/modules/globalRingtone.api.module'
import realmRingtoneApiModule from '@/modules/api/csp/modules/realmRingtone.api.module'
import rolesApiModule from '@/modules/api/csp/modules/roles.api.module'
import permissionsApiModule from '@/modules/api/csp/modules/permissions.api.module'
import callHistoryApiModule from '@/modules/api/csp/modules/callHistory.api.module'
import abandonedCallsApiModule from '@/modules/api/csp/modules/abandonedCalls.api.module'
import urlProxyApiModule from '@/modules/api/csp/modules/urlProxy.api.module'
import egressApiModule from '@/modules/api/csp/modules/egress.api.module'
import connectionSettingsApiModule from '@/modules/api/csp/modules/connectionSettings.api.module'
import contactBookApiModule from '@/modules/api/csp/modules/contactBook.api.module'
import speedDialApiModule from '@/modules/api/csp/modules/speedDial.api.module'
import adrApiModule from '@/modules/api/csp/modules/adr.api.module'
import heldApiModule from '@/modules/api/csp/modules/held.api.module'
import contactGroupsApiModule from '@/modules/api/csp/modules/contactGroups.api.module'
import layoutsApiModule from '@/modules/api/csp/modules/layouts.api.module'
import callNotesApiModule from '@/modules/api/csp/modules/callNotesApiModule'
import usersApiModule from '@/modules/api/csp/modules/users.api.module'
import logosApiModule from '@/modules/api/csp/modules/logos.api.module'
import globalSettingsApiModule from '@/modules/api/csp/modules/globalSettings.api.module'
import realmSettingsApiModule from '@/modules/api/csp/modules/realmSettings.api.module'
import userSettingsApiModule from '@/modules/api/csp/modules/userSettings.api.module'
import fccMasterRegistryApiModule from '@/modules/api/csp/modules/fccMasterRegistry.api.module'
import syncApiModule from '@/modules/api/csp/modules/sync.api.module'
import languageApiModule from '@/modules/api/csp/modules/language.api.module'
import translationsApiModule from '@/modules/api/csp/modules/translations.api.module'
import globalDashboardApiModule from '@/modules/api/csp/modules/globalDashboard.api.module'
import realmDashboardApiModule from '@/modules/api/csp/modules/realmDashboard.api.module'
import userDashboardApiModule from '@/modules/api/csp/modules/userDashboard.api.module'
import incorrectLocationApiModule from '@/modules/api/csp/modules/incorrectLocation.api.module'
import callLinesApiModule from '@/modules/api/csp/modules/callLines.api.module'
import callLogsApiModule from '@/modules/api/csp/modules/callLogs.api.module'
import callActivityLogsApiModule from '@/modules/api/csp/modules/callActivityLogs.api.module'
import prerecordedAudiosApiModule from '@/modules/api/csp/modules/prerecordedAudios.api.module.js'
import TTYPredefinedMessagesApiModule from '@/modules/api/csp/modules/TTYPredefinedMessages.api.module'

const apiService = {
  auth: authApiModule(instance),
  user: userApiModule(instance),
  sip: sipApiModule(instance),
  globalRingtone: globalRingtoneApiModule(instance),
  realmRingtone: realmRingtoneApiModule(instance),
  roles: rolesApiModule(instance),
  permissions: permissionsApiModule(instance),
  callHistory: callHistoryApiModule(instance),
  abandonedCalls: abandonedCallsApiModule(instance),
  urlProxy: urlProxyApiModule(instance),
  egress: egressApiModule(instance),
  connectionSettings: connectionSettingsApiModule(instance),
  contactBook: contactBookApiModule(instance),
  contactGroups: contactGroupsApiModule(instance),
  adrApiModule: adrApiModule(instance),
  heldApiModule: heldApiModule(instance),
  speedDial: speedDialApiModule(instance),
  callNotes: callNotesApiModule(instance),
  layouts: layoutsApiModule(instance),
  users: usersApiModule(instance),
  logos: logosApiModule(instance),
  globalSettings: globalSettingsApiModule(instance),
  realmSettings: realmSettingsApiModule(instance),
  userSettings: userSettingsApiModule(instance),
  fccMasterRegistry: fccMasterRegistryApiModule(instance),
  syncApiModule: syncApiModule(instance),
  languages: languageApiModule(instance),
  translations: translationsApiModule(instance),
  globalDashboard: globalDashboardApiModule(instance),
  realmDashboard: realmDashboardApiModule(instance),
  userDashboard: userDashboardApiModule(instance),
  incorrectLocation: incorrectLocationApiModule(instance),
  callLines: callLinesApiModule(instance),
  callLogs: callLogsApiModule(instance),
  callActivityLogs: callActivityLogsApiModule(instance),
  prerecordedAudios: prerecordedAudiosApiModule(instance),
  ttyMessages: TTYPredefinedMessagesApiModule(instance),
  instance
}

export default apiService
